import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const Root = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: ${breakpoints.small}px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: ${breakpoints.large}px) {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 0 20px;
  }

  & > a {
    margin: 20px 30px;
    height: 90%;
    display: block;
    background: #ffffff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 40px 0 40px 40px;
    padding: 30px;
    text-align: center;
    transition: all 0.3s ease 0s;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    &:hover {
      text-decoration: none;

      &:before {
        opacity: 1;
      }

      h5,
      p {
        color: #fff;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background-image: linear-gradient(135deg, #11416b 0%, #5c83e3 100%);
      transition: all 0.3s ease 0s;
      opacity: 0;
    }

    .icon {
      width: 67px;
      height: 67px;
      line-height: 70px;
      margin: auto;
      position: relative;
      margin-bottom: 20px;
      background: #f6f4fd;
      border-radius: 22px 0 22px 22px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 40px;
        height: 40px;
      }
    }

    & > h5 {
      font-size: 1rem;
      color: #3b566e;
      letter-spacing: 0.7px;
      margin-bottom: 15px;
      position: relative;
      z-index: 2;
      transition: all 0.3s ease 0s;
    }

    & > p {
      font-weight: 400;
      font-size: 0.9rem;
      color: #6f8ba4;
      letter-spacing: 0.88px;
      line-height: 26px;
      position: relative;
      z-index: 2;
      transition: all 0.3s ease 0s;
      margin-bottom: 20px;
    }

    @media (min-width: ${breakpoints.large}px) {
      p {
        font-size: 1.2rem;
      }
      h5 {
        font-size: 1.3rem;
      }
    }
  }
`

export default {
  Root,
}
