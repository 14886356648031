import React from "react"
import E from "./Services.style"
import proptype from "prop-types"
import Service from "./Service"

const Services = ({ services }) => {
  return (
    <E.Root>
      {services.map(service => (
        <Service key={service.id} service={service} />
      ))}
    </E.Root>
  )
}

Services.prototype = {
  services: proptype.array,
}
Services.defultProps = {
  services: [],
}

export default Services
