import React from "react"
import E from "./Step.style"

const Step = ({ id, text, icon }) => {
  return (
    <E.Root>
        <span>{id}</span>
        <E.ImgWrapper id={id}>
          <img src={icon} alt={text} />
        </E.ImgWrapper>
        <h3>{text}</h3>
    </E.Root>
  )
}

export default Step
