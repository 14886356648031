import styled from "styled-components"

const InputField = styled.input`
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  transition: all 0.1s ease-in;
  outline: none;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  appearance: none;
  background-color: #fcfcfc;

  &::placeholder {
    color: #666;
  }

  &:focus {
    transition: 0.1s ease-in-out;
    padding-right: 30px;
    border: 1px solid #b1aded;
    background-color: #fff;
  }

  &.notValid {
    border: 2px solid #b1aded;
  }
`

export default {
  InputField,
}
