import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const E = {
  Root: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;

    @media (max-width: ${breakpoints.large}px) {
      flex-direction: column;
    }
  `,
}

export default E
