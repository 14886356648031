import React, { useState } from "react"
import E from "./Team.style"
import proptype from "prop-types"
import Employee from "./Employee"

const Team = ({ employees }) => {
  const [caruselPosition, setCaruselPosition] = useState(-350)


  const moveCaruselForward = () => {
    if (caruselPosition < (employees.length - 2) * 100) {
      setCaruselPosition(caruselPosition + 100)
    }
  }

  const moveCaruselBack = () => {
    if (caruselPosition > -350) {
      setCaruselPosition(caruselPosition - 100)
    }
  }

  return (
    <E.Root>
      {caruselPosition < (employees.length - 2) * 40 && (
        <E.BottonForward onClick={moveCaruselForward} />
      )}
      <E.Wrapper>
        {employees.map(employee => (
          <Employee
            caruselPosition={caruselPosition}
            key={employee.name}
            employee={employee}
          />
        ))}
      </E.Wrapper>
      {caruselPosition > -270  && <E.BottonBack onClick={moveCaruselBack} />}
    </E.Root>
  )
}

Team.prototype = {
  employees: proptype.array,
}
Team.defultProps = {
  employees: [],
}

export default Team
