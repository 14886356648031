import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const E = {
  Root: styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    position: relative;
    background: white;
  `,
  Wrapper: styled.div`
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    overflow: hidden;
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.extraMedium}px) {
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      :nth-child(6) {
        background: yellow;
      }
    }

    @media (max-width: ${breakpoints.medium}px) {
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
    }
  `,
  BottonForward: styled.div`
    position: absolute;
    top: 50%;
    left: 2%;
    display: none;
    height: 15px;
    width: 15px;
    border-bottom: 4px solid currentColor;
    border-left: 4px solid currentColor;
    transform: translateY(-50%) rotate(45deg);

    &:hover {
      cursor: pointer;
    }
    @media (min-width: ${breakpoints.medium}px) {
      display: block;
    }
    @media (min-width: ${breakpoints.large}px) {
      display: none;
    }
    @media (max-width: ${breakpoints.small}px) {
      display: none;
    }
  `,
  BottonBack: styled.div`
    position: absolute;
    top: 49%;
    right: 2%;
    display: none;
    height: 15px;
    width: 15px;
    border-bottom: 4px solid currentColor;
    border-left: 4px solid currentColor;
    transform: translateY(-50%) rotate(-135deg);

    &:hover {
      cursor: pointer;
    }
    @media (min-width: ${breakpoints.medium}px) {
      display: block;
    }
    @media (min-width: ${breakpoints.large}px) {
      display: none;
    }
    @media (max-width: ${breakpoints.small}px) {
      display: none;
    }
  `,
}

export default E
