import React from "react"
import E from "./Employee.style"
import Link from "../Link"

const Employee = ({ employee, caruselPosition }) => {
  let socialLinksArr = []

  if (employee.facebook) {
    socialLinksArr.push(
      <li>
        <Link to={employee.facebook}>Facebook</Link>
      </li>
    )
  }

  if (employee.tweeter) {
    socialLinksArr.push(
      <li>
        <Link to={employee.tweeter}>Tweeter</Link>
      </li>
    )
  }

  if (employee.linkdin) {
    socialLinksArr.push(
      <li>
        <Link to={employee.linkdin}>Linkdin</Link>
      </li>
    )
  }

  const links = socialLinksArr.length ? (
    <ul>{socialLinksArr.map(social => social)}</ul>
  ) : null

  return (
    <E.EmployeeBox
      caruselPosition={caruselPosition}
      style={{ backgroundImage: `url(${employee.avatar})` }}
      positionEmployee = {employee.position}
    >
      <E.EmployeeContent>
        <E.EmployeeTextBox>
          <div>
            <E.EmployeeName>{employee.name}</E.EmployeeName>
            <span>{employee.job}</span>
          </div>
          <p>{employee.info}</p>
        </E.EmployeeTextBox>
        <E.EmployeeHeader>
          <E.EmployeeName>{employee.name}</E.EmployeeName>
          <span>{employee.job}</span>
        </E.EmployeeHeader>
        {links}
      </E.EmployeeContent>
    </E.EmployeeBox>
  )
}

export default Employee
