import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import Team from "../components/Team"
import LeadForm from "../components/LeadForm"
import styled from "styled-components"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"
import employees from "../../data/employees"
import services from "../../data/services"
import Services from "../components/Services"
import Steps from "../components/Steps"
import steps from "../../data/steps"

const E = {
  Row: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media (max-width: ${breakpoints.medium}px) {
      display: block;
    }

    .text {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;

      @media (max-width: ${breakpoints.medium}px) {
        max-width: 100%;
        text-align: center;
      }

      h3 {
        font-weight: bold;
        margin: 20px 0 20px 0;
      }

      p {
        font-weight: 300;
        padding-left: 30px;
        padding-bottom: 30px;
      }
    }

    .form {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;

      @media (max-width: ${breakpoints.medium}px) {
        max-width: 100%;
      }
    }
  `,

  Team: styled.div`
    @media (min-width: ${breakpoints.large}px) {
      position: relative;
      top: -91px;
      background: #fff;
    }
  `,
}

const IndexPage = () => (
  <Layout>
    <Seo title="בית" />
    <section>
      <Hero />
      <E.Team>
        <h2 style={{ paddingTop: "80px" }}>האנשים מאחורי פולאריס</h2>
        <Team employees={employees} />
      </E.Team>
    </section>

    <section className="colored">
      <h2>השירותים שלנו</h2>
      <Services services={services} />
    </section>

    <section className="colored" style={{ paddingBottom: "60px" }}>
      <h2> שלבי תכנון פיננסי / פרישה </h2>
      <Steps steps={steps} />
    </section>

    <section>
      <div className="container">
        <E.Row>
          <div className="text">
            <h3>שמור על קשר</h3>
            <p>
              נשמח לשוחח איתך על הדרך שבה נוכל לעזור לך למטב את תיק הנכסים
              הפנסיונים והפיננסים שלך
            </p>
          </div>
          <div className="form">
            <LeadForm />
          </div>
        </E.Row>
      </div>
    </section>
  </Layout>
)

export default IndexPage
