import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"
import palette from "../GlobalStyle/globals/palette"

const EmployeeBox = styled.div`
  flex: 0 0 33%;
  position: relative;
  margin-bottom: 40px;
  margin-top: 45px;
  background-repeat: no-repeat;
  background-position: ${({ positionEmployee }) => (positionEmployee ? positionEmployee : "50% 7%")}; // Arranges the photos of Adi and Amir
  flex-direction: column;
  transition: 0.2s ease-in-out;
  min-height: 380px;
  background-size: cover;

  &:last-child {
    background-position: top;
  }

  transform: translateX(${({ caruselPosition }) => caruselPosition + "%"});

  @media (max-width: ${breakpoints.large}px) {
    height: 200px;
    max-width: 50%;
    flex: 0 0 60%;
    background-position: ${({ positionEmployee }) => (positionEmployee ? "0 23%" : "50% 5%")}; // Arranges the photos of Adi and Amir
    padding: 16px
  }

  @media (min-width: ${breakpoints.large}px) {
    transform: none;
  }

  @media (max-width: ${breakpoints.medium}px) {
    max-width: 60%;
    flex: 0 0 60%;
    transform: none;  

  }
  
  @media (max-width: ${breakpoints.small}px) {
    max-width: 80%;
    flex: 0 0 80%;

  }
`

const EmployeeContent = styled.div`
  text-align: center;
  overflow: hidden;
  margin-top: 0;
  font-size: 14px;
  line-height: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;

  & .EmployeeHeader {
  }

  p {
    padding: 0 25px;
    font-weight: 400;
    font-size: 0.9rem;
    height: 100%;
    margin-bottom: 25px;
    color: #fff;
  }
`

const EmployeeHeader = styled.div`
  background-image: linear-gradient(135deg, #6a30d1 0%, #5c83e3 100%);
  background: ${palette.primary.main};
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  span {
    display: block;
    font-weight: 400;
    font-size: 0.9rem;
    color: #e5e9f7;
    letter-spacing: 0.5px;
  }

`

const EmployeeTextBox = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0;
  background: rgba(17, 65, 107, 0.9);
  transition: 0.2s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 2;

  & > div {
    margin: 15px 0;
    width: 100%;

    span {
      font-size: 0.9rem;
      color: #fff;
      padding-bottom: 5px;
      border-bottom: 2px solid #fff;
      width: 100%;
    }
  }
  &:hover {
    opacity: 1;
  }

  @media (max-width: ${breakpoints.large}px) {
     padding: 12px 13px;
  }
`
const EmployeeName = styled.h3`
  display: block;
  font-weight: 500;
  font-size: 1.1rem;
  color: #fff;
  letter-spacing: 0.69px;
  margin-bottom: 3px;
`

export default {
  EmployeeBox,
  EmployeeContent,
  EmployeeTextBox,
  EmployeeHeader,
  EmployeeName,
}
