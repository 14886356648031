import meetingImg from "../assets/images/meeting.png"
import analyticsImg from "../assets/images/brain.png"
import secMeetingImg from "../assets/images/plan.png"
import talkImg from "../assets/images/research.png"
import formImg from "../assets/images/air.png"

const steps = [
  {
    id: "01",
    text: "פגישה ראשונית",
    icon: meetingImg,
  },
  {
    id: "02",
    text: "איסוף וניתוח המידע",
    icon: analyticsImg,
  },
  {
    id: "03",
    text: "פגישה לסקירת תיק כולל ומתן המלצות",
    icon: secMeetingImg,
  },
  {
    id: "04",
    text: "יישום המלצות מול החברות השונות ומס הכנסה",
    icon: talkImg,
  },
  {
    id: "05",
    text: "בקרה וליווי מתמשך",
    icon: formImg,
  },
]

export default steps
