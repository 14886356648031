import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const Root = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  padding-top: 0px;
  padding-bottom: 80px;
  width: 100%;
    justify-content: space-around;
  @supports (justify-content: space-evenly) {
    justify-content: space-evenly;
  }

  @media (min-width: ${breakpoints.large}px) {
    padding-top: 0;
    padding-bottom: 80px;
    position: relative;
  }
`

export default {
  Root,
}
