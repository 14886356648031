import React from "react"
import E from "./Service.style"
import Link from "../Link"

const Service = ({ service }) => {
  return (
    <E.Root>
      <Link to={`/services/?val=${service.id - 1}`}>
        <div className="icon">
          {service.avatar && <img src={service.avatar} alt="" />}
        </div>
        <h5>{service.name}</h5>
        <p>{service.short}</p>
      </Link>
    </E.Root>
  )
}

export default Service
