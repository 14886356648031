import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"

const E = {
  Root: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    flex-direction: column;
    position: relative;
    width: 20%;
    position: relative;

    @media (max-width: ${breakpoints.large}px) {
      padding-bottom: 40px;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      border-width: 1px 0 0 0;
      border-style: dashed;
      height: 100%;
      border-color: #cedae4;
      width: 100%;
      left: -50%;
      top: 86px;

      @media (max-width: ${breakpoints.large}px) {
        right: -54px;
        top: 0;
        left: auto;
        transform: rotate(90deg);
        top: 100%;
      }
    }

    span {
      text-align: center;
      font-size: 1.3rem;
      padding-bottom: 0;
      margin-bottom: 10px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 14px;
        height: 2px;
        border-radius: 4px;
        background-color: #d1dee8;
        margin: 0 auto;
      }

      @media (max-width: ${breakpoints.large}px) {
        width: 50%;
        height: 50%;
        text-align: right;
      }
    }

    h3 {
      width: 100%;
      min-height: 70px;
      text-align: center;
      padding-top: 10px;
      font-weight: normal;

      @media (max-width: ${breakpoints.large}px) {
        text-align: right;
      }

      @media (max-width: ${breakpoints.small}px) {
        font-size: 1.1rem;
      }
    }

    @media (max-width: ${breakpoints.large}px) {
      width: 340px;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      display: block;
      overflow: hidden;
    }

    @media (max-width: ${breakpoints.small}px) {
      width: 90%;
    }
  `,

  ImgWrapper: styled.div`
    height: 90px;
    width: 90px;
    margin: 1px auto;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 1px solid #f0f6fb;
    z-index: 2;

    img {
      height: 50px;
      width: auto;
      opacity: 0.7;
    }

    @media (max-width: ${breakpoints.large}px) {
      float: right;
      margin-left: 30px;
    }
  `,
}

export default E
