import React, { useState } from "react"
import E from "./LeadForm.style"
import Button from "../Button"
import InputFiled from "../InputField"
import { db } from "../../firebase"

const LeadForm = () => {
  const [formSendMessage, SetFormSendMessage] = useState(false)
  const [formValid, SetFormValid] = useState("")
  const [formData, SetFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    mailingList: false,
  })

  const SubmitForm = e => {
    e.preventDefault()
    const phoneNumberValid = /(^[0-9]+[-]*[0-9]+$)/
    const mailValid = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    if (formData.fullName !== "" && formData.email !== "") {
      if (formData.phoneNumber !== "") {
        if (
          formData.phoneNumber.length < 8 ||
          formData.phoneNumber.length > 10 ||
          phoneNumberValid.test(formData.phoneNumber) !== true
        ) {
          SetFormValid("phoneNumber")
          return
        } else {
          if (
            formData.email !== "" &&
            formData.email.match(mailValid) === null
          ) {
            SetFormValid("email")
            return
          }
          SetFormValid("")
          db.collection("Leads").add({
            ...formData,
          })
          SetFormSendMessage(true)
          SetFormData({
            fullName: "",
            phoneNumber: "",
            email: "",
            mailing: false,
          })
          return
        }
      } else {
        if (formData.email !== "" && formData.email.match(mailValid) === null) {
          SetFormValid("email")
          return
        }
        SetFormValid("")
        db.collection("Leads").add({
          ...formData,
        })
        SetFormSendMessage(true)
        SetFormData({
          fullName: "",
          phoneNumber: "",
          email: "",
          mailing: false,
        })
        return
      }
    }
    if (formData.fullName === "") {
      SetFormValid("fullName")
      SetFormSendMessage(false)
    } else if (formData.email === "") {
      SetFormValid("email")
      SetFormSendMessage(false)
    }
  }

  const setLeadData = e => {
    SetFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const setcheckboxData = e => {
    SetFormData({ ...formData, mailingList: e.target.checked })
  }

  return (
    <E.Root>
      <E.FormWrapper>
        <InputFiled
          required
          className={formValid === "fullName" ? "notValid" : ""}
          inputName={"fullName"}
          onChangeCallback={setLeadData}
          InputType={"text"}
          inputValue={formData.fullName}
          placeholderText="שם מלא *"
          autoComplete="off"
        />
        {formValid === "fullName" ? <h5>שם מלא הינו שדה חובה</h5> : ""}
        <InputFiled
          required
          inputValue={formData.phoneNumber}
          className={formValid === "phoneNumber" ? "notValid" : ""}
          inputName={"phoneNumber"}
          InputType={"text"}
          placeholderText="מספר טלפון"
          onChangeCallback={setLeadData}
          autoComplete="off"
        />
        {formValid === "phoneNumber" ? <h5>נא להזין מספר טלפון תקין</h5> : ""}
        <InputFiled
          className={formValid === "email" ? "notValid" : ""}
          inputValue={formData.email}
          inputName={"email"}
          InputType={"email"}
          onChangeCallback={setLeadData}
          placeholderText="דואר אלקטרוני *"
          autoComplete="off"
        />
        {formValid === "email" ? (
          <h5>נא להזין כתובת דואר אלקטרוני תקינה</h5>
        ) : (
          ""
        )}
        <div className="mailingBox">
          <input
            value={formData.mailing}
            type="checkbox"
            id="mail"
            name="mailing"
            onChange={setcheckboxData}
          />
          <label htmlFor="mailing">
            אני מאשר את הוספת הפרטים שלי לרשימת התפוצה לטובת קבלת מידע
            ועידכונים.
          </label>
        </div>
        <Button variant="submit" buttonColor="dark" onClick={SubmitForm}>
          שליחה
        </Button>
        {formSendMessage && <h5>תודה רבה על פנייתך!</h5>}
      </E.FormWrapper>
    </E.Root>
  )
}

export default LeadForm
