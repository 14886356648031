import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"
import palette from "../GlobalStyle/globals/palette"
import bgImg from "../../../assets/images/herobg.jpg"

const Root = styled.div`
  position: relative;

  @media (min-width: ${breakpoints.large}px) {
    overflow: hidden;
  }
  
  .welcome {
    overflow: hidden;
    position: relative;
    z-index: 1;
    
    h1 {
      color: #11416b;
      display: none;
      text-align: center;
      padding: 12px 16px;
      font-size: 23px;
  
      @media (max-width: ${breakpoints.large}px) {
        display: block;
      }
    }
    
    
    & img {
      max-width: 100%;
      height: auto;
      float: left;
      
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-height: 1000px;
      }
    }
    
    @media (min-width: ${breakpoints.large}px) {
      float: left;
      width: 80%;
    }
    
    @media (max-width: ${breakpoints.large}px) {
      background: transparent url(${bgImg}) no-repeat scroll center center;
      background-size: cover;
      min-height: 84vh;
      position: relative;
      
      .desktop {
        display: none;
      }
    }
  }
  
  .mobile {
    display: none;
    width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translateY(-50%);
    margin: 0 auto;
    max-height: 90%;
    
    @media (max-width: ${breakpoints.large}px) {
      display: block;
    }
  }

  .bg {
    position: absolute;
    left: 0;
    width: 100%;
    min-height: 500px;
    z-index: 2;
  }

  .bgSVG {
    max-width: 101%;
    width: 101%;
    height: auto;
    transform: scaleX(-1);
    
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
    }
  }

  .heroText {
    position: absolute;
    transform: perspective(1px) translateY(0%);
    z-index: 4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    width: 40vw;
    padding: 40px;
    margin-right: 30px;
    min-height: 560px;
    height: 85%;
    flex-direction: column;

    h1, p {
      width: 100%;
    }
    
    h2 {
      display: none;
    }
    
    @media (max-width: ${breakpoints.large}px) {
      h1:first-child {
        display: none;
      }
      
      h2 {
        display: block;
      }
    }
    
    @media (max-width: ${breakpoints.extraLarge}px) {
      height: auto;
      
      & h1 {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      
      & p {
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
    }
    
    @media (min-width: ${breakpoints.extraLarge}px) {
      & p {
        font-size: 1.1rem;
      }
    }
  }

  p {
    position: relative;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-bottom: 30px;
    margin-left: 30px;
  }

  @media (max-width: ${breakpoints.large}px) {
    top: 0;
    
    .bg {
      display: none;
    }
    
    .heroText {
      width: 100%;
      min-height: 1px;
      align-items: center;
      margin-right: 0px;
      padding: 0px 30px 5px 30px;
      justify-content: center;
      color: #fff;
      top: 50%;
      transform: perspective(1px) translateY(-50%) !important;
      position: relative;
      bottom: -110px;
      color: ${palette.primary.main};
      
      & h1 {
        line-height: 30px;
        margin-bottom: 15px;
        margin-top: 2px;
        text-align: center;
      }
      
      & p {
        line-height: 22px;
        padding: 0px 60px;
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0px;
        padding-bottom: 10px;
        text-align: center;
      }
      
      & p::before {
        content: unset;
      }
    }
  }

  @media (max-width: ${breakpoints.small}px) {
    .heroText {
      & h1 {
        font-size: 23px;
        margin-bottom: 21px;
      }
      
      & p {
        font-size: 14px;
        padding: 0;
        margin-bottom: 5px;
        
        &::before {
          content: unset;
        }
      }
    }
  }
`

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }

  @media (max-width: ${breakpoints.large}px) {
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin-left: 5px;
    }
  }
`

export default {
  Root,
  Buttons,
}
