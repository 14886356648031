import amirImage from "../assets/images/amir.jpg"
import omerImage from "../assets/images/omer.jpg"
import vadimImage from "../assets/images/vadim.jpg"
import adiImage from "../assets/images/adi.png"
import ruthImage from "../assets/images/pic15.jpeg"
import sharonImage from "../assets/images/pic16.jpg"
import max from "../assets/images/max.jpg"
import yaelImage from "../assets/images/yael.png"
import Iztik from "../assets/images/itsik2.jpg"
import Guy from "../assets/images/guy.png"


//TODO: move this into graphql data.
const employees = [
  {
    name: "אמיר הסל",
    avatar: amirImage,
    job: "שותף",
    info: `
    אמיר הקים את חטיבת ההשקעות בהראל ביטוח וניהל אותה במשך כ- 18 שנים. במהלך כהונתו, אמיר, השיג לתיק ההשקעות של הראל את התשואה הגבוהה ביותר במצטבר בין חברות הביטוח וקרנות הפנסיה. לאמיר תואר ראשון ושני בכלכלה ומנהל עסקים מאוניברסיטת תל אביב, שניהם בהצטיינות.`,
  },
  {
    name: "עומר הרם",
    avatar: omerImage,
    job: "שותף מנהל",
    info: `
    עומר בעל ניסיון של מעל 20 שנים בתחום הביטוח והפיננסים ובתפקידו האחרון היה סמנכ"ל בחברת כלל ביטוח ופיננסים. לעומר תואר ראשון, רישיון משווק השקעות מותלה , רישיון סוכן פנסיוני ואלמנטרי מטעם משרד האוצר.
    `,
  },
  {
    name: "ודים טרבלסקי",
    avatar: vadimImage,
    job: "שותף מנהל",
    info: `
    ודים בעל ניסיון במגוון תפקידים בחברות ביטוח משנת 2004. ודים הקים את המרכז לתכנון פרישה בהראל ביטוח בשנת 2013 ובתפקידו האחרון שימש כסמנכ“ל. לודים תואר ראשון B.A בביטוח , תואר M.B.A מנהל עסקים , רישיון משווק השקעות מותלה, רישיון סוכן פנסיוני מטעם משרד האוצר.`,
  },
  {
    name: "יעל ברורמן",
    avatar: yaelImage,
    job: "מנהלת קשרי לקוחות",
    info: `יעל בעלת ניסיון של מעל 10 שנים בתחום הביטוח והפיננסים. בתפקידה האחרון הייתה אחראית על השירות והתפעול לסוכני חברת הביטוח הפניקס. בעלת B.A במנהל עסקים, התמחות במימון.`,
  },
  {
    name: "עדי שוולטר",
    avatar: adiImage,
    job: "מנהלת קשרי לקוחות",
    info: `עדי בעלת ניסיון של 4 שנים בתחום הביטוח והפיננסים. בתפקיד האחרון הייתה אחראית על השירות והתפעול לסוכני חברת הביטוח הפניקס. בעלת תואר MBA במנהל עסקים והתמחות במימון. B.A במנהל עסקים.`,
    position: "0% 30%",
  },
  {
    name: "מקס טריבלסקי",
    avatar: max,
    job: "שותף מנהל",
    info: `Meet Max, a seasoned expert in the family office business with over 13 years of experience. As the owner of Maxa Multi Family Office,
    he meticulously manages the asset allocation for 23 families, ensuring their wealth grows and prospers. With Max's expertise, your financial future is in trusted hands
`,
  },
  {
    name: "שושנה שרון שחור",
    avatar: sharonImage,
    job: "מתכננת פיננסית",

    info: `
    שושנה בעלת ניסיון במגוון תפקידים בתחום הפיננסים.
    ב-15 שנה האחרונים עבדה במגוון תפקידים בהראל חברה לביטוח ובתפקידה האחרון שימשה כמתכננת פיננסית בכירה במרכז לתכנון פרישה בהראל.
    לשושנה תואר דו-מסלולי בניהול וכלכלה, ורישיון סוכן פנסיוני מטעם משרד האוצר.`,
  }, 
  {
    name: "איציק טיבי",
    avatar: Iztik,
    job: 'מתכנן פיננסי',
    info: `איציק בעל ניסיון של כ18 שנים במגוון תפקידים בתחום הפיננסים.
     .ב15 שנה האחרונים עבד במגוון תפקידים בהראל חברה לביטוח ותפקידו האחרון היה מתכנן פיננסי בכיר בהראל
     מתמחה בבניית אסטרטגיית השקעות ותכנון פיננסי למשפחות בעלות עושר פיננסי, יחדים בעלי ממון.
     איציק בעל תואר ראשון בכללה וניהול ובעל רישיון סוכן פנסיוני ומטעם משרד האוצר.
    `
  },
  {
    name: "גיא אבן טוב",
    avatar: Guy,
    job: 'מתכנן פיננסי',
    info: `גיא בעל ניסיון של 25 שנים בתחום הביטוח והפיננסים. שימש במגוון תפקידים בבתי ההשקעות
        ובחברות הביטוח בניהול השקעות וכמנהל חדר מסחר.
        ב -15 שנים האחרונות היה יועץ השקעות בבנק איגוד במחלקת הפלטינום ,וייעץ ללקוחות הגדולים
        ביותר של הבנק. לגיא ניסיון רב וידע מעמיק בכל תחומי שוק ההון, בארץ ובחו&quot;ל.
        לגיא תואר ראשון במימון מטעם המכללה למינהל, רישיון מנהל תיקים מטעם הרשות לניירות ערך -
        מותלה . ובעל רישיון סוכן פנסיוני מטעם משרד האוצר.
        גיא בן נשוי לאיריס ולהם שלושה ילדים, רועי, דניאלה ורני.
    `
  }
]


export default employees
