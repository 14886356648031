import React from "react"
import E from "./Steps.style"
import Step from "./Step"

const Steps = ({ steps }) => {
  return (
    <E.Root>
      {steps.map(step => (
        <Step key={step.id} {...step} />
      ))}
    </E.Root>
  )
}

export default Steps
