import styled from "styled-components"

const Root = styled.form``

const FormWrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h5 {
    padding: 8px 20px;
    font-size: 1.1rem;
    font-weight: 300;
    background: #f7f7f7;
    display: block;
    width: 100%;
    margin: 10px 0;
    border-radius: 40px 0 40px 40px;
    text-align: center;
    color: #3b566e;
  }

  & .mailingBox {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0;
    label {
      padding-right: 10px;
    }
  }
`

const FormMessage = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
`

export default {
  Root,
  FormWrapper,
  FormMessage,
}
