import React from "react"
import Button from "../Button"
import bgImg from "../../../assets/images/polaris.jpg"
// import bgImg2 from "../../../assets/images/circle.png"
import bgImg3 from "../../../assets/images/circle2.png"
import bgSvg from "../../../assets/images/bg.svg"
import E from "./Hero.style"
import { navigate } from "gatsby"

const Hero = () => {
  const handleClick = () => {
    navigate("/about")
  }

  const handleFileClick = () => {
    navigate("/economicgoals")
  }

  return (
    <E.Root>
      
      <div className="welcome">
        <h1>ברוכים הבאים<br/>להחלטות פיננסיות נכונות</h1>
        <img alt="" className="desktop" src={bgImg} />
        <img alt="" className="mobile" src={bgImg3} />
      </div>

      <div className="bg">
        <img className="bgSVG" alt="" src={bgSvg} />
      </div>

      <div className="heroText">
        <h1>ברוכים הבאים<br/>להחלטות פיננסיות נכונות</h1>
        <h2>אודות פולאריס</h2>

        <p>
          אנחנו בפולאריס מאמינים שבעולם המורכב של השקעות, חיסכון פנסיוני, פרישה,
          מיסוי וביטוח, נדרשים מקצועיות ויחס אישי על מנת שתוכלו לקבל את התמונה
          הטובה ביותר שתביא אתכם לקבל את ההחלטות הנכונות ביותר למיטוב תיק הנכסים הפיננסים והפנסיוניים.
        </p>

        <E.Buttons>
          <Button
            className="heroButton"
            size="medium"
            buttonColor="light"
            onClick={handleClick}
          >
            קרא עוד
          </Button>
          <Button
            className="heroButton"
            size="medium"
            buttonColor="light"
            onClick={handleFileClick}
          >
            מה היעדים הכלכליים שלך?
          </Button>
        </E.Buttons>
      </div>
    </E.Root>
  )
}

export default Hero
