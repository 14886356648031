import React from "react"
import PropTypes from "prop-types"
import E from "./InputField.style"

const callback = () => {
  return ""
}

const InputField = ({
  placeholderText,
  InputType,
  inputValue,
  isRequired,
  onChangeCallback,
  inputName,
  className,
}) => {
  return (
    <E.InputField
      className={className}
      type={InputType}
      placeholder={placeholderText}
      required={isRequired}
      value={inputValue}
      onChange={onChangeCallback}
      name={inputName}
    />
  )
}

InputField.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChangeCallback: PropTypes.func,
  inputName: PropTypes.string,
}
InputField.defaultProps = {
  type: "text",
  onChangeCallback: callback,
}

export default InputField
